import axios from "axios";

const apiService = axios.create({
    baseURL: process.env.VUE_APP_ROCK_RMS_API_URL,
    withCredentials: true,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
})

export default {

    get(resource: string) {
        return apiService.get(resource);
    },

    post(resource: string, data: any) {
        return apiService.post(resource, data);
    },
    postWithConfig(resource: string, data: any, config: any) {
        return apiService.post(resource, data, config);
    },

    put(resource: string, data: any) {
        return apiService.put(resource, data);
    },

    delete(resource: string) {
        return apiService.delete(resource);
    },
}